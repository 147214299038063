<template>
    <Modal class-name="edit_power" v-model="editStatus" :title="type=='group'?'修改角色权限':'修改管理员权限'" width="1100" @on-ok="comData" @on-visible-change="closemod" :loading="subLoading" :closable="false">
      <div class="power-out">
        <div class="polis" v-for="(item,key) in data" :key="key">
          <div class="pomenutit">
            <Checkbox :value="item.checked" :indeterminate="item.indeterminate" :disabled="item.disabled" @on-change="selAll($event,key)">{{item.mutitle||''}}</Checkbox>
          </div>
          <div class="powerw">
            <CheckboxGroup v-model="item.selid" @on-change="selOne($event,key)">
              <Checkbox :label="p.id" v-for="(p,k) in item.plist" :key="k" :disabled="p.disabled">{{p.title||''}}</Checkbox>
            </CheckboxGroup>
          </div>
        </div>
      </div>
      <Spin size="large" fix v-if="page_loading"></Spin>
    </Modal>
</template>
<script>
export default {
  name:'edit_power',
  data(){
    return {
      editStatus:false,
      subLoading:true,
      type:'',
      id:0,
      data:{},
      page_loading:false
    }
  },
  methods:{
    open:function (type,id){
      var _this=this;
      this.type=type;
      this.id=id;
      _this.editStatus=true;
      _this.page_loading=true;
      this.requestApi('/adm/show_power.html',{'type':type,'id':id}).then(function (res){
        _this.page_loading=false;
        if(res.status==1){
          _this.data=res.data;
        }
      })
    },
    /**
     * 保存
     */
    comData:function (){
      var _this=this,data=[];
      for(let key in this.data){
        data=data.concat(this.data[key].selid);
      }
      this.requestApi('/adm/save_power.html',{'type':this.type,'id':this.id,'data':data}).then(function (res){
        if(res.status==1){
          _this.tipSuccess(res.msg);
          _this.editStatus =false;
        }else{
          _this.subLoading = false;
          setTimeout(function (){_this.subLoading = true;},5);
          _this.alertError(res.msg);
        }
      })
    },
    /**
     * 全选
     */
    selAll:function (status,key){
      var data=this.data[key].plist;
      this.data[key].selid=[];
      if(!status){//取消全选
        for(let i=0;i<data.length;i++){
          if(data[i].disabled) this.data[key].selid.push(data[i].id);
        }
        if(this.data[key].selid.length==0){
          this.data[key].indeterminate=false;
          this.data[key].checked=false;
        }else{
          this.data[key].indeterminate=true;
          this.data[key].checked=false;
        }
      }else{//全选
        for(let i=0;i<data.length;i++){
          this.data[key].selid.push(data[i].id);
        }
        this.data[key].indeterminate=false;
        this.data[key].checked=true;
      }
    },
    /**
     * 单个 选择
     */
    selOne:function (selval,key){
      var all_length=this.data[key].plist.length;
      if(selval.length==all_length){
        this.data[key].indeterminate=false;
        this.data[key].checked=true;
      }else if(selval.length==0){
        this.data[key].indeterminate=false;
        this.data[key].checked=false;
      }else{
        this.data[key].indeterminate=true;
        this.data[key].checked=true;
      }
    },
    /**
     * 关闭弹窗
     */
    closemod:function (status){
      if(!status){
        this.type='';
        this.id=0;
        this.data={};
      }
    }
  }
}
</script>

<style lang="less">
  @import "~@/assets/css/power/edit_power";
</style>