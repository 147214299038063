<template>
  <div class="sgroup">
    <div class="searchw">
      <Form inline :label-width="52">
        <FormItem label="用户名" :label-width="53" style="width: 160px">
          <Input maxlength="32" v-model="posdata.uname" @on-keyup.13="search"></Input>
        </FormItem>
        <FormItem label="实名" :label-width="40" style="width: 138px">
          <Input maxlength="32" v-model="posdata.relname" @on-keyup.13="search"></Input>
        </FormItem>
        <FormItem label="手机号" :label-width="53" style="width: 150px">
          <Input maxlength="11" v-model="posdata.user_phone" @on-keyup.13="search"></Input>
        </FormItem>
        <FormItem label="角色" :label-width="40" style="width: 165px">
          <Select style="width: 120px" v-model="posdata.group_id" :class="[posdata.group_id=='0'?'no-sel':'']" @on-change="search">
            <Option value="0">不限</Option>
            <Option v-for="(p,key) in group" :value="key" :key="key">{{p}}</Option>
          </Select>
        </FormItem>
        <FormItem label="" :label-width="0">
          <Button type="default" icon="ios-search" @click="search" @keyup.13="search">搜索</Button>
        </FormItem>
        <Button style="float: right;" type="info" icon="md-person-add" @click="add_super">添加管理员</Button>
      </Form>
    </div>
    <Table size="small" :columns="columns" :data="data.data" :loading="table_loading">
      <template slot-scope="row" slot="title">{{row.row.title||'--'}}</template>
      <template slot-scope="row" slot="single">{{row.row.single?'单点登录':'多点登录'}}</template>
      <template slot-scope="row" slot="locked">
        <span v-if="row.row.locked" style="color: #ff0000;">已锁定</span>
        <span v-else>正常</span>
      </template>
      <template slot-scope="row" slot="action">
        <span v-if="row.row.id==1">--</span>
        <Button v-else type="info" size="small" style="margin-right: 10px" @click="edit_super(row.index)">修改资料</Button>
        <Button v-if="!row.row.master" type="primary" size="small" @click="showPower(row.row.id)">修改权限</Button>
      </template>
    </Table>
    <div class="footer-m">
      <Page class-name="pagew page-r" :total="data.total" :current="data.current_page" :page-size="data.per_page" :page-size-opts="[15,30,50]" show-sizer show-total @on-change="page" @on-page-size-change="change_pagesize" />
    </div>
    <Modal v-model="addu.status" width="360" :title="addu.data.id>0?'修改管理员信息':'添加管理员'" :loading="addu.loading" @on-ok="save_super" @on-visible-change="closeaddg">
      <Form ref="form" :label-width="68" :model="addu.data" :rules="rules">
        <FormItem label="用户名" prop="uname">
          <Input maxlength="15" v-model="addu.data.uname"></Input>
        </FormItem>
        <FormItem label="真实姓名" prop="relname">
          <Input maxlength="15" v-model="addu.data.relname"></Input>
        </FormItem>
        <FormItem label="手机号" prop="user_phone">
          <Input maxlength="11" v-model="addu.data.user_phone"></Input>
        </FormItem>
        <FormItem label="角色" prop="group_id">
          <Select v-model="addu.data.group_id">
            <Option v-for="(p,key) in group" :value="key" :key="key">{{p}}</Option>
          </Select>
        </FormItem>
        <FormItem label="登录限制">
          <RadioGroup v-model="addu.data.single">
            <Radio :label="0">多点登录</Radio>
            <Radio :label="1">单点登录</Radio>
          </RadioGroup>
        </FormItem>
        <FormItem label="密码">
          <Input type="password" password v-model="addu.data.upass" maxlength="32" :placeholder="addu.data.id>0?'不填则保持原密码':'密码必填(8-32位)'"></Input>
        </FormItem>
        <FormItem label="口令">
          <Input type="password" password v-model="addu.data.uword" maxlength="32" :placeholder="addu.data.id>0?'不填则保持原口令':'口令必填(8-32位)'"></Input>
        </FormItem>
        <FormItem label="状态">
          <i-switch size="large" v-model="addu.data.locked" :true-value="0" :false-value="1">
            <span slot="open">正常</span>
            <span slot="close">禁用</span>
          </i-switch>
        </FormItem>
      </Form>
    </Modal>
    <edit-power ref="editPower"></edit-power>
  </div>
</template>
<script>
import editPower from "@/views/power/edit_power"
export default {
  name: 'SuperGroup',
  components:{editPower},
  data(){
    return {
      columns:[
        {title: '管理员ID',key: 'id', width: 90},
        {title: '用户名',key: 'uname',minWidth:100},
        {title: '真实姓名',key: 'relname',minWidth:100},
        {title: '手机号',key: 'user_phone',minWidth:100},
        {title: '角色',slot: 'title',minWidth:100},
        {title: '多点/单点登录',slot: 'single',minWidth:100},
        {title: '状态',slot: 'locked'},
        {title: '操作',slot:'action',width:160,align:'center'},
      ],
      data:[],
      table_loading:false,
      group:{},
      addu:{
        data:{id:0,uname:'',upass:'',uword:'',relname:'',user_phone:'',single:1,locked:0,group_id:''},
        status:false,
        loading:true,
      },
      rules:{
        uname:[{type:'string',required:true,message:'请填写用户名',trigger:'blur'}],
        relname:[{type:'string',required:true,message:'请填写真实姓名',trigger:'blur'}],
        user_phone:[{type:'string',required:true,message:'请填写手机号',trigger:'blur'}],
        group_id:[{type:'string',required:true,message:'请选择角色',trigger:'blur'}]
      },
      posdata:{
        uname:'',
        relname:'',
        user_phone:'',
        group_id:'0',
        page:1,
        page_size:15
      }
    }
  },
  created() {
    this.init();
  },
  methods:{
    init:function (){
      var _this=this;
      this.table_loading=true;
      this.requestApi('/adm/super.html',this.posdata).then(function (res){
        _this.table_loading=false;
        if(res.status==1){
          _this.data=res.data;
          if(_this.posdata.page==1) _this.group=res.group;
        }
      })
    },
    /**
     * 添加管理员弹窗
     */
    add_super:function (){
      this.addu.status=true;
    },
    /**
     * 修改管理员弹窗
     */
    edit_super:function (key){
      let data=JSON.parse(JSON.stringify(this.data.data[key]));
      this.addu.data={id:data.id,uname:data.uname,upass:'',uword:'',relname:data.relname,user_phone:data.user_phone,single:data.single,locked:data.locked,group_id:data.group_id.toString()};
      this.addu.status=true;
    },
    /**
     * 保存管理员添加/修改
     */
    save_super:function (){
      var _this=this;
      this.$refs.form.validate((valid) => {
        if (valid) {
          if(this.addu.data.id==0){
            if(this.addu.data.upass==''){
              _this.addu.loading = false;
              setTimeout(function (){_this.addu.loading=true;},5);
              return _this.alertError('请填写密码！')
            }
            if(this.addu.data.uword==''){
              _this.addu.loading = false;
              setTimeout(function (){_this.addu.loading=true;},5);
              return _this.alertError('请填写口令！')
            }
          }
          this.requestApi('/adm/save_super.html', this.addu.data).then(function (res){
            if(res.status == 1){
              _this.init();
              _this.tipSuccess(res.msg);
              _this.addu.status = false;
            }else{
              _this.addu.loading = false;
              setTimeout(function (){_this.addu.loading=true;},5);
              _this.alertError(res.msg);
            }
          })
        }else{
          _this.addu.loading = false;
          setTimeout(function (){_this.addu.loading=true;},5);
        }
      })
    },
    /**
     * 关闭编辑管理员弹出
     */
    closeaddg:function (status){
      if(!status){
        this.addu={
          data:{id:0,uname:'',upass:'',uword:'',relname:'',user_phone:'',single:1,locked:0,group_id:''},
          status:false,
          loading:true
        }
      }
    },
    /**
     * 打开权限弹出
     */
    showPower:function (id){
      this.$refs.editPower.open('super',id);
    },
    /**
     * 搜索
     */
    search:function (){
      this.posdata.page=1;
      this.init();
    },
    /**
     * 翻页
     */
    page:function (page){
      this.posdata.page=page;
      this.init();
    },
    /**
     * 切换分页条数
     */
    change_pagesize:function (size){
      this.posdata.page_size=size;
      if(this.posdata.page==1) this.init();
    }
  }
}
</script>
<style lang="less">
.nouse{}
</style>